import cx from 'classnames'
import { FunctionComponent } from 'react'
import Lottie from 'lottie-react'
import animationData from '../animations/spinner.json'

const Spinner: FunctionComponent<{
  className?: string
  size: number
}> = ({ className, size }) => {
  return (
    <Lottie
      animationData={animationData}
      className={cx(className, {
        'w-32 h-32': size === 6,
        'w-20 h-20': size === 4,
      })}
      autoplay
    />
  )
}

export default Spinner
