import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { addYears, endOfDay, isAfter } from 'date-fns'
import { differenceInDays } from 'date-fns'
import { orderBy, keyBy, groupBy, mapValues, partition } from 'lodash'
import api from '../../api'
import {
  Contact,
  ContactExtended,
  ContactPayload,
  Contacts,
} from '../../api/types'
import { RootState } from '../store'
import { selectAuth } from './authSlice'

// Define a type for the slice state
interface ContactsState {
  areContactsLoaded: boolean
  contacts: Record<string, Contact>
  initialContactIdsWithoutBirthdayData: number[]
  getContactsError: string
  updateContactError: string
  createContactError: string
  isUpdatingContact: boolean
  isCreatingContact: boolean
  isDeletingContact: boolean
  error: string
}

// Define the initial state using that type
const initialState: ContactsState = {
  areContactsLoaded: false,
  initialContactIdsWithoutBirthdayData: [],
  contacts: {},
  getContactsError: '',
  updateContactError: '',
  createContactError: '',
  isUpdatingContact: false,
  isCreatingContact: false,
  isDeletingContact: false,
  error: '',
}

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async function () {
    const response = await api.fetchContacts()
    return response
  }
)

export const createContact = createAsyncThunk(
  'contacts/createContact',
  async function (
    { payload }: { payload: ContactPayload },
    { rejectWithValue }
  ) {
    try {
      const response = await api.createContact(payload)
      return response
    } catch (err) {
      return rejectWithValue((err as Error).message)
    }
  }
)

export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async function (
    {
      contactId,
      payload,
    }: {
      contactId: number
      payload: ContactPayload
    },
    { rejectWithValue }
  ) {
    try {
      const response = await api.updateContactWithId(contactId, payload)
      return response
    } catch (err) {
      return rejectWithValue((err as Error).message)
    }
  }
)

export const updateContactName = createAsyncThunk(
  'contacts/updateContact',
  async function ({ contactId, name }: { contactId: number; name: string }) {
    const response = await api.updateContactNameWithId(contactId, name)
    return response
  }
)

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async function ({ contactId }: { contactId: number }) {
    const response = await api.deleteContactWithId(contactId)
    return response
  }
)

const countByName = (contacts: Contacts) => {
  const counts: Record<string, number> = {}
  for (let i = 0; i < contacts.length; i += 1) {
    const { name } = contacts[i]
    const lowerCaseName = name.toLowerCase()

    if (counts[lowerCaseName]) {
      counts[lowerCaseName] = counts[lowerCaseName] += 1
    } else {
      counts[lowerCaseName] = 1
    }
  }

  return counts
}

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getContacts.fulfilled,
      (state, action: PayloadAction<Contacts>) => {
        state.contacts = keyBy(action.payload, 'id')
        state.areContactsLoaded = true
        const contactWithoutBirthdayData = action.payload.filter(
          noSelfBirthdayProvidedByMemberFilter
        )
        state.initialContactIdsWithoutBirthdayData =
          contactWithoutBirthdayData.map((contact) => contact.id)
      }
    )

    builder.addCase(getContacts.rejected, (state, action) => {
      state.areContactsLoaded = true
      state.getContactsError = action.error.message || ''
    })

    builder.addCase(getContacts.pending, (state) => {
      state.areContactsLoaded = false
      state.getContactsError = ''
    })

    builder.addCase(updateContact.fulfilled, (state, action) => {
      const contact = state.contacts[action.payload.id]
      state.contacts = {
        ...state.contacts,
        [action.payload.id]: { ...contact, ...action.payload },
      }
      state.isUpdatingContact = false
    })

    builder.addCase(updateContact.rejected, (state, action) => {
      state.isUpdatingContact = false
      state.updateContactError = action.error.message || ''
    })

    builder.addCase(updateContact.pending, (state) => {
      state.updateContactError = ''
      state.isCreatingContact = true
    })

    builder.addCase(createContact.fulfilled, (state, action) => {
      state.contacts = {
        ...state.contacts,
        [action.payload.id]: action.payload,
      }
      state.isCreatingContact = false
    })

    builder.addCase(createContact.rejected, (state, action) => {
      state.createContactError = action.error.message || ''
      state.isCreatingContact = false
    })

    builder.addCase(deleteContact.pending, (state, action) => {
      delete state.contacts[action.meta.arg.contactId]
    })

    builder.addCase(deleteContact.rejected, (state, action) => {
      state.error = action.error.message || ''
      state.isDeletingContact = false
    })

    builder.addCase(deleteContact.fulfilled, (state, action) => {
      delete state.contacts[action.meta.arg.contactId]
      state.isDeletingContact = false
    })
  },
})

export const selectContactsState = (state: RootState) => state.contacts
export const selectContactsCountByName = ({
  contacts: contactsState,
}: RootState) => countByName(Object.values(contactsState.contacts))

export const selectContacts = createSelector(
  [selectContactsState, selectContactsCountByName],
  ({ contacts }, countsByName): Record<string, ContactExtended> => {
    const families = Object.entries(groupBy(contacts, 'familyId')).map(
      ([familyId, familyContacts]) => {
        return {
          familyId: familyId,
          name: getChildrenNamesString(familyContacts),
        }
      }
    )

    return mapValues(contacts, (contact) => {
      const nameCount = countsByName[contact?.name?.toLowerCase()] || 0
      const contactFamily = families.find(
        (f) => f.familyId === contact.familyId
      )
      return {
        ...contact,
        familyName: contactFamily?.name,
        nameCount,
      }
    })
  }
)

export const selectContactById = createSelector(
  [
    // Usual first input - extract value from `state`
    selectContacts,
    // Take the second arg, `contactId`, and forward to the output selector
    (state: RootState, contactId?: number | null) => contactId || null,
  ],
  // Output selector gets (`contacts, contactId)` as args
  (contacts, contactId) => (contactId ? contacts[contactId] : null)
)

const orderContactsByChildrenFirst = (contacts: ContactExtended[]) =>
  orderBy(contacts, ['isChild'], ['desc'])

export const orderContactsByBirthdayAsc = <C extends Contact>(contacts: C[]) =>
  orderBy(
    contacts,
    ({ birthdayMonth, birthdayDay }) => {
      if (birthdayDay && birthdayMonth) {
        const monthIndex = birthdayMonth - 1
        const now = endOfDay(new Date())

        const birthdayDateObject = endOfDay(
          new Date(new Date().getFullYear(), monthIndex, birthdayDay)
        )

        const isBirthdayPassedThisYear = isAfter(now, birthdayDateObject)

        const diff = isBirthdayPassedThisYear
          ? differenceInDays(addYears(birthdayDateObject, 1), now)
          : differenceInDays(birthdayDateObject, now)

        return diff
      }
    },
    ['asc']
  )

const getChildrenNamesString = (familyContacts: Contact[]) => {
  const childrenNames = familyContacts
    .filter((contact) => contact.isChild)
    .map((child) => child.name)

  return childrenNames.join(', ')
}

export const selectFamilyAlbumContactsByFamily = createSelector(
  [selectContacts, selectAuth],
  (contacts, { profile: { userAdminFamilies } }) => {
    const faContacts = Object.values(contacts).filter(
      (contact) => !!contact.familyId
    )
    const families = groupBy(faContacts, 'familyId')

    const familiesWithMetadata = Object.entries(families).map(
      ([familyId, contactsForFamily]) => {
        const familyMetadata = userAdminFamilies.find(
          (family) => family.familyId === familyId
        )

        return {
          familyId: familyId,
          isAdmin: familyMetadata?.isAdmin,
          familyCreatedAt: familyMetadata?.createdAt,
          contacts: orderContactsByChildrenFirst(contactsForFamily),
          name: getChildrenNamesString(contactsForFamily),
        }
      }
    )

    return orderBy(
      familiesWithMetadata,
      ['isAdmin', 'familyCreatedAt'],
      ['desc', 'desc']
    )
  }
)

export const selectFamilyAlbumContactsOrderedByBirthdayGroupedByFamily =
  createSelector(
    [selectContacts, selectAuth],
    (contacts, { profile: { userAdminFamilies } }) => {
      const faContacts = Object.values(contacts).filter(
        (contact) => !!contact.familyId
      )
      const families = groupBy(faContacts, 'familyId')

      const familiesWithMetadata = Object.entries(families).map(
        ([familyId, familyContacts]) => {
          const familyMetadata = userAdminFamilies.find(
            (family) => family.familyId === familyId
          )

          return {
            isAdmin: familyMetadata?.isAdmin,
            familyCreatedAt: familyMetadata?.createdAt,
            contacts: orderContactsByBirthdayAsc(familyContacts),
            name: getChildrenNamesString(familyContacts),
          }
        }
      )

      return orderBy(
        familiesWithMetadata,
        ['isAdmin', 'familyCreatedAt'],
        ['desc', 'desc']
      )
    }
  )

export const selectFamilyAlbumFamilies = createSelector(
  [selectContacts, selectAuth],
  (contacts, { profile: { userAdminFamilies } }) => {
    const faContacts = Object.values(contacts).filter(
      (contact) => !!contact.familyId
    )
    const families = groupBy(faContacts, 'familyId')

    const familiesWithMetadata = Object.entries(families).map(
      ([familyId, familyContacts]) => {
        const familyMetadata = userAdminFamilies.find(
          (family) => family.familyId === familyId
        )

        return {
          familyId: familyMetadata?.familyId,
          name: getChildrenNamesString(familyContacts),
        }
      }
    )

    return familiesWithMetadata
  }
)

const noBirthdayFilter = (contact: Contact) =>
  !contact.birthdayDay && !contact.birthdayMonth

const noSelfBirthdayProvidedByMemberFilter = (contact: Contact) =>
  noBirthdayFilter(contact) || !contact.wasBirthdayAddedBySelf // Not provided by user nor by the member himself

export const selectNonFamilyAlbumContacts = createSelector(
  [selectContacts],
  (contacts) => Object.values(contacts).filter((contact) => !contact.familyId)
)

export const selectNonFamilyAlbumContactsOrderedByBirthday = createSelector(
  [selectNonFamilyAlbumContacts],
  (contacts) => orderContactsByBirthdayAsc(contacts)
)

export const countContacts = createSelector([selectContacts], (contacts) => {
  const [other, familyAlbum] = partition(
    Object.values(contacts),
    (contact) => !contact.familyId
  )

  return {
    other: other.length,
    familyAlbum: familyAlbum.length,
  }
})

export const selectContactsError = createSelector(
  [selectContactsState],
  (state) => {
    return (
      state.getContactsError ||
      state.updateContactError ||
      state.createContactError
    )
  }
)

export default contactsSlice.reducer
