import { isUndefined, keyBy } from 'lodash'
import { createContext } from 'react'
import Rollbar from 'rollbar'
import { EventType } from './api/types'
import { zonedTimeToUtc } from 'date-fns-tz'

export type QuickSelectionGiftCard = {
  code: string
  name: string
  badge?: '10%OFF'
}

export type ICountryConfig = {
  code: string
  name: string
  flag: string
  defaultCurrency: string
  quickSelectionGiftCards: QuickSelectionGiftCard[]
}

export const COUNTRIES_CONFIG: ICountryConfig[] = [
  {
    code: 'US',
    name: 'United States',
    flag: '🇺🇲',
    defaultCurrency: 'USD',
    quickSelectionGiftCards: [
      {
        code: 'FAMILY-ALBUM',
        name: 'FamilyAlbum',
      },
      { code: 'AMZ-US', name: 'Amazon' },
      { code: 'DARDN-US', name: 'Darden Restaurants' },
      { code: 'TARGT-US', name: 'Target' },
      { code: 'DUNKIN-US', name: "Dunkin'" },
    ],
  },
  {
    code: 'CA',
    name: 'Canada',
    flag: '🇨🇦',
    defaultCurrency: 'CAD',
    quickSelectionGiftCards: [
      {
        code: 'FAMILY-ALBUM-CA',
        name: 'FamilyAlbum',
      },
      {
        code: 'AMZ-CA',
        name: 'Amazon',
      },
      {
        code: 'WMART-CA',
        name: 'Walmart',
      },
      {
        code: 'DDASH-CA',
        name: 'DoorDash',
      },
      {
        code: 'SOB-CA',
        name: 'Sobeys',
      },
    ],
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
    defaultCurrency: 'GBP',
    quickSelectionGiftCards: [
      {
        code: 'FAMILY-ALBUM-GB',
        name: 'FamilyAlbum',
      },
      {
        code: 'JEAT-GB',
        name: 'Just Eat',
      },
      {
        code: 'MAS-GB',
        name: 'Marks and Spencer',
      },
      {
        code: 'BSHOP-GB',
        name: 'The Body Shop',
      },
      {
        code: 'NERO-GB',
        name: 'Caffè Nero',
      },
    ],
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: '🇦🇺',
    defaultCurrency: 'AUD',
    quickSelectionGiftCards: [
      {
        code: 'FAMILY-ALBUM-AU',
        name: 'FamilyAlbum',
      },
      {
        code: 'TARGT-AU',
        name: 'Target',
      },
      {
        code: 'MYER-AU',
        name: 'Myer',
      },
      {
        code: 'COLES-AU',
        name: 'Coles',
      },
      {
        code: 'PLINE-AU',
        name: 'Priceline Pharmacy',
      },
    ],
  },
]

export const TIMEZONE_TO_COUNTRY_CODE: Record<string, string> = {
  'Europe/Andorra': 'AD',
  'Asia/Dubai': 'AE',
  'Asia/Kabul': 'AF',
  'Europe/Tirane': 'AL',
  'Asia/Yerevan': 'AM',
  'Antarctica/Casey': 'AQ',
  'Antarctica/Davis': 'AQ',
  'Antarctica/Mawson': 'AQ',
  'Antarctica/Palmer': 'AQ',
  'Antarctica/Rothera': 'AQ',
  'Antarctica/Troll': 'AQ',
  'Antarctica/Vostok': 'AQ',
  'America/Argentina/Buenos_Aires': 'AR',
  'America/Argentina/Cordoba': 'AR',
  'America/Argentina/Salta': 'AR',
  'America/Argentina/Jujuy': 'AR',
  'America/Argentina/Tucuman': 'AR',
  'America/Argentina/Catamarca': 'AR',
  'America/Argentina/La_Rioja': 'AR',
  'America/Argentina/San_Juan': 'AR',
  'America/Argentina/Mendoza': 'AR',
  'America/Argentina/San_Luis': 'AR',
  'America/Argentina/Rio_Gallegos': 'AR',
  'America/Argentina/Ushuaia': 'AR',
  'Pacific/Pago_Pago': 'US',
  'Europe/Vienna': 'AT',
  'Australia/Lord_Howe': 'AU',
  'Antarctica/Macquarie': 'AU',
  'Australia/Hobart': 'AU',
  'Australia/Melbourne': 'AU',
  'Australia/Sydney': 'AU',
  'Australia/Broken_Hill': 'AU',
  'Australia/Brisbane': 'AU',
  'Australia/Lindeman': 'AU',
  'Australia/Adelaide': 'AU',
  'Australia/Darwin': 'AU',
  'Australia/Perth': 'AU',
  'Australia/Eucla': 'AU',
  'Asia/Baku': 'AZ',
  'America/Barbados': 'BB',
  'Asia/Dhaka': 'BD',
  'Europe/Brussels': 'BE',
  'Europe/Sofia': 'BG',
  'Atlantic/Bermuda': 'BM',
  'Asia/Brunei': 'BN',
  'America/La_Paz': 'BO',
  'America/Noronha': 'BR',
  'America/Belem': 'BR',
  'America/Fortaleza': 'BR',
  'America/Recife': 'BR',
  'America/Araguaina': 'BR',
  'America/Maceio': 'BR',
  'America/Bahia': 'BR',
  'America/Sao_Paulo': 'BR',
  'America/Campo_Grande': 'BR',
  'America/Cuiaba': 'BR',
  'America/Santarem': 'BR',
  'America/Porto_Velho': 'BR',
  'America/Boa_Vista': 'BR',
  'America/Manaus': 'BR',
  'America/Eirunepe': 'BR',
  'America/Rio_Branco': 'BR',
  'Asia/Thimphu': 'BT',
  'Europe/Minsk': 'BY',
  'America/Belize': 'BZ',
  'America/St_Johns': 'CA',
  'America/Halifax': 'CA',
  'America/Glace_Bay': 'CA',
  'America/Moncton': 'CA',
  'America/Goose_Bay': 'CA',
  'America/Toronto': 'CA',
  'America/Nipigon': 'CA',
  'America/Thunder_Bay': 'CA',
  'America/Iqaluit': 'CA',
  'America/Pangnirtung': 'CA',
  'America/Winnipeg': 'CA',
  'America/Rainy_River': 'CA',
  'America/Resolute': 'CA',
  'America/Rankin_Inlet': 'CA',
  'America/Regina': 'CA',
  'America/Swift_Current': 'CA',
  'America/Edmonton': 'CA',
  'America/Cambridge_Bay': 'CA',
  'America/Yellowknife': 'CA',
  'America/Inuvik': 'CA',
  'America/Dawson_Creek': 'CA',
  'America/Fort_Nelson': 'CA',
  'America/Whitehorse': 'CA',
  'America/Dawson': 'CA',
  'America/Vancouver': 'CA',
  'Indian/Cocos': 'CC',
  'Europe/Zurich': 'CH',
  'Africa/Abidjan': 'CI',
  'Pacific/Rarotonga': 'CK',
  'America/Santiago': 'CL',
  'America/Punta_Arenas': 'CL',
  'Pacific/Easter': 'CL',
  'Asia/Shanghai': 'CN',
  'Asia/Urumqi': 'CN',
  'America/Bogota': 'CO',
  'America/Costa_Rica': 'CR',
  'America/Havana': 'CU',
  'Atlantic/Cape_Verde': 'CV',
  'Indian/Christmas': 'CX',
  'Asia/Nicosia': 'CY',
  'Asia/Famagusta': 'CY',
  'Europe/Prague': 'CZ',
  'Europe/Berlin': 'DE',
  'Europe/Copenhagen': 'DK',
  'America/Santo_Domingo': 'DO',
  'Africa/Algiers': 'DZ',
  'America/Guayaquil': 'EC',
  'Pacific/Galapagos': 'EC',
  'Europe/Tallinn': 'EE',
  'Africa/Cairo': 'EG',
  'Africa/El_Aaiun': 'EH',
  'Europe/Madrid': 'ES',
  'Africa/Ceuta': 'ES',
  'Atlantic/Canary': 'ES',
  'Europe/Helsinki': 'FI',
  'Pacific/Fiji': 'FJ',
  'Atlantic/Stanley': 'FK',
  'Pacific/Chuuk': 'FM',
  'Pacific/Pohnpei': 'FM',
  'Pacific/Kosrae': 'FM',
  'Atlantic/Faroe': 'FO',
  'Europe/Paris': 'FR',
  'Europe/London': 'GB',
  'Asia/Tbilisi': 'GE',
  'America/Cayenne': 'GF',
  'Europe/Gibraltar': 'GI',
  'America/Nuuk': 'GL',
  'America/Danmarkshavn': 'GL',
  'America/Scoresbysund': 'GL',
  'America/Thule': 'GL',
  'Europe/Athens': 'GR',
  'Atlantic/South_Georgia': 'GS',
  'America/Guatemala': 'GT',
  'Pacific/Guam': 'GU',
  'Africa/Bissau': 'GW',
  'America/Guyana': 'GY',
  'Asia/Hong_Kong': 'HK',
  'America/Tegucigalpa': 'HN',
  'America/Port-au-Prince': 'HT',
  'Europe/Budapest': 'HU',
  'Asia/Jakarta': 'ID',
  'Asia/Pontianak': 'ID',
  'Asia/Makassar': 'ID',
  'Asia/Jayapura': 'ID',
  'Europe/Dublin': 'IE',
  'Asia/Jerusalem': 'IL',
  'Asia/Kolkata': 'IN',
  'Asia/Calcutta': 'IN',
  'Indian/Chagos': 'IO',
  'Asia/Baghdad': 'IQ',
  'Asia/Tehran': 'IR',
  'Atlantic/Reykjavik': 'IS',
  'Europe/Rome': 'IT',
  'America/Jamaica': 'JM',
  'Asia/Amman': 'JO',
  'Asia/Tokyo': 'JP',
  'Africa/Nairobi': 'KE',
  'Asia/Bishkek': 'KG',
  'Pacific/Tarawa': 'KI',
  'Pacific/Kanton': 'KI',
  'Pacific/Kiritimati': 'KI',
  'Asia/Pyongyang': 'KP',
  'Asia/Seoul': 'KR',
  'Asia/Almaty': 'KZ',
  'Asia/Qyzylorda': 'KZ',
  'Asia/Qostanay': 'KZ',
  'Asia/Aqtobe': 'KZ',
  'Asia/Aqtau': 'KZ',
  'Asia/Atyrau': 'KZ',
  'Asia/Oral': 'KZ',
  'Asia/Beirut': 'LB',
  'Asia/Colombo': 'LK',
  'Africa/Monrovia': 'LR',
  'Europe/Vilnius': 'LT',
  'Europe/Luxembourg': 'LU',
  'Europe/Riga': 'LV',
  'Africa/Tripoli': 'LY',
  'Africa/Casablanca': 'MA',
  'Europe/Monaco': 'MC',
  'Europe/Chisinau': 'MD',
  'Pacific/Majuro': 'MH',
  'Pacific/Kwajalein': 'MH',
  'Asia/Yangon': 'MM',
  'Asia/Ulaanbaatar': 'MN',
  'Asia/Hovd': 'MN',
  'Asia/Choibalsan': 'MN',
  'Asia/Macau': 'MO',
  'America/Martinique': 'MQ',
  'Europe/Malta': 'MT',
  'Indian/Mauritius': 'MU',
  'Indian/Maldives': 'MV',
  'America/Mexico_City': 'MX',
  'America/Cancun': 'MX',
  'America/Merida': 'MX',
  'America/Monterrey': 'MX',
  'America/Matamoros': 'MX',
  'America/Mazatlan': 'MX',
  'America/Chihuahua': 'MX',
  'America/Ojinaga': 'MX',
  'America/Hermosillo': 'MX',
  'America/Tijuana': 'MX',
  'America/Bahia_Banderas': 'MX',
  'Asia/Kuala_Lumpur': 'MY',
  'Asia/Kuching': 'MY',
  'Africa/Maputo': 'MZ',
  'Africa/Windhoek': 'NA',
  'Pacific/Noumea': 'NC',
  'Pacific/Norfolk': 'NF',
  'Africa/Lagos': 'NG',
  'America/Managua': 'NI',
  'Europe/Amsterdam': 'NL',
  'Europe/Oslo': 'NO',
  'Asia/Kathmandu': 'NP',
  'Pacific/Nauru': 'NR',
  'Pacific/Niue': 'NU',
  'Pacific/Auckland': 'NZ',
  'Pacific/Chatham': 'NZ',
  'America/Panama': 'PA',
  'America/Lima': 'PE',
  'Pacific/Tahiti': 'PF',
  'Pacific/Marquesas': 'PF',
  'Pacific/Gambier': 'PF',
  'Pacific/Port_Moresby': 'PG',
  'Pacific/Bougainville': 'PG',
  'Asia/Manila': 'PH',
  'Asia/Karachi': 'PK',
  'Europe/Warsaw': 'PL',
  'America/Miquelon': 'PM',
  'Pacific/Pitcairn': 'PN',
  'America/Puerto_Rico': 'PR',
  'Asia/Gaza': 'PS',
  'Asia/Hebron': 'PS',
  'Europe/Lisbon': 'PT',
  'Atlantic/Madeira': 'PT',
  'Atlantic/Azores': 'PT',
  'Pacific/Palau': 'PW',
  'America/Asuncion': 'PY',
  'Asia/Qatar': 'QA',
  'Indian/Reunion': 'RE',
  'Europe/Bucharest': 'RO',
  'Europe/Belgrade': 'RS',
  'Europe/Kaliningrad': 'RU',
  'Europe/Moscow': 'RU',
  'Europe/Simferopol': 'RU',
  'Europe/Kirov': 'RU',
  'Europe/Volgograd': 'RU',
  'Europe/Astrakhan': 'RU',
  'Europe/Saratov': 'RU',
  'Europe/Ulyanovsk': 'RU',
  'Europe/Samara': 'RU',
  'Asia/Yekaterinburg': 'RU',
  'Asia/Omsk': 'RU',
  'Asia/Novosibirsk': 'RU',
  'Asia/Barnaul': 'RU',
  'Asia/Tomsk': 'RU',
  'Asia/Novokuznetsk': 'RU',
  'Asia/Krasnoyarsk': 'RU',
  'Asia/Irkutsk': 'RU',
  'Asia/Chita': 'RU',
  'Asia/Yakutsk': 'RU',
  'Asia/Khandyga': 'RU',
  'Asia/Vladivostok': 'RU',
  'Asia/Ust-Nera': 'RU',
  'Asia/Magadan': 'RU',
  'Asia/Sakhalin': 'RU',
  'Asia/Srednekolymsk': 'RU',
  'Asia/Kamchatka': 'RU',
  'Asia/Anadyr': 'RU',
  'Africa/Kigali': 'RW',
  'Asia/Riyadh': 'SA',
  'Pacific/Guadalcanal': 'SB',
  'Indian/Mahe': 'SC',
  'Africa/Khartoum': 'SD',
  'America/Paramaribo': 'SR',
  'Africa/Juba': 'SS',
  'Africa/Sao_Tome': 'ST',
  'America/El_Salvador': 'SV',
  'Asia/Damascus': 'SY',
  'America/Grand_Turk': 'TC',
  'Africa/Ndjamena': 'TD',
  'Indian/Kerguelen': 'TF',
  'Africa/Lome': 'TG',
  'Asia/Bangkok': 'TH',
  'Asia/Dushanbe': 'TJ',
  'Pacific/Fakaofo': 'TK',
  'Asia/Dili': 'TL',
  'Asia/Ashgabat': 'TM',
  'Africa/Tunis': 'TN',
  'Pacific/Tongatapu': 'TO',
  'Europe/Istanbul': 'TR',
  'America/Port_of_Spain': 'TT',
  'Pacific/Funafuti': 'TV',
  'Asia/Taipei': 'TW',
  'Africa/Dar_es_Salaam': 'TZ',
  'Europe/Kiev': 'UA',
  'Europe/Uzhgorod': 'UA',
  'Europe/Zaporozhye': 'UA',
  'Africa/Kampala': 'UG',
  'Pacific/Midway': 'US',
  'Pacific/Wake': 'US',
  'America/Montevideo': 'UY',
  'Asia/Samarkand': 'UZ',
  'Asia/Tashkent': 'UZ',
  'Europe/Vatican': 'VA',
  'America/St_Vincent': 'VC',
  'America/Caracas': 'VE',
  'America/Tortola': 'VG',
  'America/St_Thomas': 'VI',
  'Asia/Ho_Chi_Minh': 'VN',
  'Pacific/Efate': 'VU',
  'Pacific/Wallis': 'WF',
  'Pacific/Apia': 'WS',
  'Asia/Aden': 'YE',
  'Indian/Mayotte': 'YT',
  'Africa/Johannesburg': 'ZA',
  'Africa/Lusaka': 'ZM',
  'Africa/Harare': 'ZW',
  'Pacific/Honolulu': 'US',
  'US/Central': 'US',
  'US/Eastern': 'US',
  'US/Pacific': 'US',
  'US/Pasific': 'US', // This is a typo in the timezone database
}

export const COUNTRIES_CONFIG_BY_CODE = keyBy(COUNTRIES_CONFIG, 'code')

export const config: {
  currentOccasions: string[]
  modalRedirectUrl: string
  clarityProjectId: string
  rollbar: Rollbar.Configuration
  testRoutesEnabled: boolean
  explanationVideoUrl: string
  nyeExplanationVideoUrl: string
  familyAlbumAutologinDeeplink: string
  familyAlbumDownloadDeeplink: string
  familyAlbumStoreDeeplink: string
  holidayExplanationVideoUrl: string
  mothersDayExplanationVideoUrl: string
  fathersDayExplanationVideoUrl: string
  supportEmail: string
  companyInfoUrl: string
  helpUrl: string
  minimumGiftCardAmount: number
  maximumGiftCardAmount: number
  supportedMarkets: Record<string, ICountryConfig>
  demoGiftValue: number
  giftCardSettings: {
    defaultPredefinedValues: number[]
    defaultGiftCardAmount: number
    step: number
  }
} = {
  companyInfoUrl: 'https://mixi.co.jp/en/',
  supportEmail: 'global-support@family-album.com',
  helpUrl:
    'https://help.family-album.com/hc/en-us/categories/7580086599961-Celebrate',
  currentOccasions: [EventType.THANK_YOU],
  familyAlbumAutologinDeeplink: import.meta.env
    .VITE_FAMILY_ALBUM_AUTOLOGIN_DEEP_LINK as string,
  familyAlbumDownloadDeeplink: import.meta.env
    .VITE_FAMILY_ALBUM_DOWNLOAD_DEEP_LINK as string,
  familyAlbumStoreDeeplink: import.meta.env
    .VITE_FAMILY_ALBUM_STORE_DEEPLINK as string,
  modalRedirectUrl: import.meta.env.VITE_MODAL_REDIRECT_URL as string,
  clarityProjectId: import.meta.env.VITE_CLARITY_PROJECT_ID || '',
  explanationVideoUrl: import.meta.env.VITE_EXPLANATION_VIDEO_URL as string,
  holidayExplanationVideoUrl: import.meta.env
    .VITE_EXPLANATION_VIDEO_HOLIDAY_URL,
  nyeExplanationVideoUrl: import.meta.env
    .VITE_EXPLANATION_VIDEO_NYE_URL as string,
  mothersDayExplanationVideoUrl: import.meta.env
    .VITE_EXPLANATION_VIDEO_MOTHERS_DAY_URL as string,
  fathersDayExplanationVideoUrl: import.meta.env
    .VITE_EXPLANATION_VIDEO_FATHERS_DAY_URL as string,
  testRoutesEnabled:
    isUndefined(import.meta.env.VITE_ENVIRONMENT) ||
    ['', 'dev', 'staging'].includes(import.meta.env.VITE_ENVIRONMENT as string),
  rollbar: {
    accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN as string,
    captureUncaught: true,
    enabled: import.meta.env.VITE_ROLLBAR_ENV !== '',
    codeVersion: import.meta.env.VITE_VERSION as string,
    environment: import.meta.env.VITE_ROLLBAR_ENV || 'local',
    captureUnhandledRejections: true,
    filterTelemetry: (e) => {
      return (
        e.type === 'network' &&
        (e.body.subtype === 'xhr' || e.body.subtype === 'fetch') &&
        (e.body.url as string).indexOf('https://rs.fullstory.com') === 0
      )
    },
    ignoredMessages: ['Loading chunk', 'Script error'],
    payload: {
      client: {
        javascript: {
          code_version: import.meta.env.VITE_VERSION as string,
          source_map_enabled: true,
        },
      },
    },
  },
  minimumGiftCardAmount: 10,
  maximumGiftCardAmount: 500,
  supportedMarkets: COUNTRIES_CONFIG_BY_CODE,
  demoGiftValue: 2.99,
  giftCardSettings: {
    defaultGiftCardAmount: 75,
    defaultPredefinedValues: [25, 50, 100],
    step: 5,
  },
}

export const ConfigContext = createContext(config)
