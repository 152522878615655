/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useState, useEffect } from 'react'
import intl from 'react-intl-universal'

const DEFAULT_LOCALE = 'en-US'

const SUPPORTED_LOCALES = [
  {
    name: 'English',
    value: 'en-US',
  },
]

const useLoadLocale = ({ version }: { version: string }) => {
  const [isLocaleInitDone, setIsLocaleInitDone] = useState(false)

  const load = async () => {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang',
    })

    const isDetetminedLocaleSupported = SUPPORTED_LOCALES.map(
      (locale) => locale.value
    ).includes(currentLocale)

    if (!isDetetminedLocaleSupported) {
      currentLocale = DEFAULT_LOCALE
    }
    try {
      const { data } = await axios.get(`/locales/${currentLocale}.json`, {
        params: { version },
      })
      await intl.init({
        currentLocale,
        locales: {
          [currentLocale]: data,
        },
      })
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    setIsLocaleInitDone(true)
  }

  useEffect(() => {
    load()
  }, [])

  return {
    isLocaleInitDone,
  }
}

export default useLoadLocale
