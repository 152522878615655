import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type InteractiveOpenMetadata = {
  firstFrameAnimationDataUrl: string
  secondFrameAnimationDataUrl: string
  firstFrameTimeout: number // 120
  secondFrameTimeout: number // 80
  loopingSoundUrl: string
  tapSoundUrl: string
  animationOpenLoopSprite: string
  animationOpenSprites: Record<string, [number, number, boolean]>
}

export const INTERACTIVE_OPENS: Record<string, InteractiveOpenMetadata> = {
  BIRTHDAY_DEFAULT: {
    firstFrameAnimationDataUrl: '/interactive-opens/BIRTHDAY_DEFAULT_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/BIRTHDAY_DEFAULT_2.json',
    firstFrameTimeout: 120,
    secondFrameTimeout: 80,
    loopingSoundUrl: '/animation_template_opening.mp3',
    tapSoundUrl: '/animation_template_transition.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  HOLIDAY_DEFAULT: {
    firstFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_DEFAULT_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_DEFAULT_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 240,
    loopingSoundUrl: '/animation_template_opening_holiday.mp3',
    tapSoundUrl: '/animation_template_transition_holiday.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  HOLIDAY_XMAS: {
    firstFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_XMAS_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_XMAS_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 240,
    loopingSoundUrl: '/animation_template_opening_xmas.mp3',
    tapSoundUrl: '/animation_template_transition_xmas.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  NYE: {
    firstFrameAnimationDataUrl: '/interactive-opens/NEW_YEAR_2022_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/NEW_YEAR_2022_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 305,
    loopingSoundUrl: '/animation_template_opening_nye.mp3',
    tapSoundUrl: '/animation_template_transition_nye.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  MOTHERS_DAY: {
    firstFrameAnimationDataUrl: '/interactive-opens/MOTHERS_DAY_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/MOTHERS_DAY_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 305,
    loopingSoundUrl: '/animation_template_opening_mothers_day.mp3',
    tapSoundUrl: '/animation_template_transition_mothers_day.mp3',
    animationOpenSprites: {
      first: [0, 6950, true],
    },
    animationOpenLoopSprite: 'first',
  },
  FATHERS_DAY: {
    firstFrameAnimationDataUrl: '/interactive-opens/FATHERS_DAY_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/FATHERS_DAY_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 320,
    loopingSoundUrl: '/animation_template_opening_fathers_day.mp3',
    tapSoundUrl: '/animation_template_transition_fathers_day.mp3',
    animationOpenSprites: {
      first: [0, 11000, true],
    },
    animationOpenLoopSprite: 'first',
  },
}

interface CelebrationAssetsState {
  interactiveOpens: Record<string, InteractiveOpenMetadata>
}

const initialState: CelebrationAssetsState = {
  interactiveOpens: INTERACTIVE_OPENS,
}

export const celebrtionAssetsSlice = createSlice({
  name: 'celebrationAssets',
  initialState,
  reducers: {},
})

export const selectCelebrationAssetsState = ({
  celebrationAssets,
}: RootState) => celebrationAssets

export default celebrtionAssetsSlice.reducer
