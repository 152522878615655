import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { keyBy, orderBy } from 'lodash'
import api from '../../api'
import { EventType, IDigitalGreetingCard } from '../../api/types'
import { RootState } from '../store'

interface DigitalGreetingCardsState {
  areGreetingCardsLoaded: boolean
  digitalGreetingCardsById: Record<string, IDigitalGreetingCard>
  error: string
}

const initialState: DigitalGreetingCardsState = {
  areGreetingCardsLoaded: false,
  digitalGreetingCardsById: {},
  error: '',
}

export const getDigitalGreetingCards = createAsyncThunk(
  'digitalGreetingCards/getDigitalGreetingCards',
  async function (eventType: EventType) {
    const response = await api.getDigitalGreetingCards(eventType)
    return response
  }
)

export const digitalGreetingCardsSlice = createSlice({
  name: 'digitalGreetingCards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDigitalGreetingCards.pending, (state) => {
      state.areGreetingCardsLoaded = false
    })

    builder.addCase(getDigitalGreetingCards.fulfilled, (state, action) => {
      state.digitalGreetingCardsById = keyBy(action.payload, 'id')
      state.areGreetingCardsLoaded = true
    })

    builder.addCase(getDigitalGreetingCards.rejected, (state, action) => {
      state.areGreetingCardsLoaded = true
      state.error = action.error.message || ''
    })
  },
})

const selectState = ({ digitalGreetingCards }: RootState) =>
  digitalGreetingCards

export const selectDgcState = createSelector([selectState], (state) => ({
  ...state,
  digitalGreetingCards: orderBy(
    state.digitalGreetingCardsById,
    ['type', 'order'],
    ['asc', 'asc']
  ).map((dgc) => ({
    ...dgc,
    thumbUrl: dgc.coverImageFileName.replace('.jpg', '_thumb.jpg'),
  })),
}))

export default digitalGreetingCardsSlice.reducer
