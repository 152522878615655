import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import celebrtionAssetsSlice from './slices/celebrationAssetsSlice'
import celebrationSlice from './slices/celebrationSlice'
import contactsSlice from './slices/contactsSlice'
import digitalGreetingCardsSlice from './slices/digitalGreetingCardsSlice'

const listenerMiddleware = createListenerMiddleware()

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  reducer: {
    auth: authSlice,
    contacts: contactsSlice,
    celebration: celebrationSlice,
    digitalGreetingCards: digitalGreetingCardsSlice,
    celebrationAssets: celebrtionAssetsSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
